import { render, staticRenderFns } from "./contact-tab-content-profile.html?vue&type=template&id=db1f33f6&scoped=true&"
import script from "./contact-tab-content-profile.vue?vue&type=script&lang=ts&"
export * from "./contact-tab-content-profile.vue?vue&type=script&lang=ts&"
import style0 from "./contact-tab-content-profile.scss?vue&type=style&index=0&id=db1f33f6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db1f33f6",
  null
  
)

export default component.exports